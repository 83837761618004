const routes = (module.exports = require('next-routes')());

/**
 *  Multilangue pattern: /:lang([a-z]{2})?
 */

routes
    .add({ name: 'home', pattern: '/:lang([a-z]{2})?', page: 'static' })
    .add({ name: 'checkEmailValid', pattern: '/:lang([a-z]{2})?/checkemailvalid', page: 'checkEmailValid' })
    .add({ name: 'resetPass', pattern: '/:lang([a-z]{2})?/resetpass', page: 'resetPassword' })
    // .add({ name: 'account', pattern: '/:lang([a-z]{2})?/account', page: 'account/account' })
    // .add({ name: 'rgpd', pattern: '/:lang([a-z]{2})?/account/rgpd', page: 'account/rgpd' })
    .add({ name: 'blogArticle', pattern: '/:lang([a-z]{2})?/blog/:_slug', page: 'blog/article' })
    .add({ name: 'categoryI18n', pattern: '/:lang([a-z]{2})?/c/:_slug+/:page([0-9]{1,})?', page: 'category' })
    .add({ name: 'staticI18n', pattern: '/:lang([a-z]{2})?/:_slug', page: 'static' });
